import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';
import {
  collection,
  addDoc,
  enableIndexedDbPersistence,
  initializeFirestore,
  CACHE_SIZE_UNLIMITED,
  query,
  getDocs,
  where,
  updateDoc,
  doc,
  deleteDoc,
} from 'firebase/firestore';

const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  authDomain: `${process.env.REACT_APP_AUTH_DOMAIN}`,
  databaseURL: `${process.env.REACT_APP_DATABASE_URL}`,
  projectId: `${process.env.REACT_APP_PROJECT_ID}`,
  storageBucket: `${process.env.REACT_APP_STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_MESSAGING_SENDER_ID}`,
  appId: `${process.env.REACT_APP_APP_ID}`,
  measurementId: `${process.env.REACT_APP_MEASUREMENT_ID}`,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
});

enableIndexedDbPersistence(db);

onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
  } else {
    // User is signed out
  }
});

/**
 * Account Creation/Management functions for Firebase
 */

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
    return true;
  } catch (err) {
    return err;
  }
};

const registerWithEmailAndPassword = async (
  name,
  email,
  password,
  occupation,
) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const { user } = res;
    await addDoc(collection(db, 'users'), {
      uid: user.uid,
      name,
      authProvider: 'local',
      email,
      occupation,
    });
    return true;
  } catch (err) {
    return err;
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return true;
  } catch (err) {
    return err;
  }
};

const logout = () => {
  signOut(auth);
  return null;
};

/**
 * Note Management functions for Firebase
 */

const saveNoteData = async (uid, title, note, reminder, created, modified) => {
  try {
    await addDoc(collection(db, 'notes'), {
      uid,
      title,
      note,
      reminder,
      created,
      modified,
    });
    return true;
  } catch (err) {
    return err;
  }
};

const updateNoteData = async (docID, title, note, reminder, modified) => {
  try {
    await updateDoc(doc(db, 'notes', docID), {
      title,
      note,
      reminder,
      modified,
    });
    return true;
  } catch (err) {
    return err;
  }
};

const deleteNoteData = async (docID) => {
  try {
    await deleteDoc(doc(db, 'notes', docID));
    return true;
  } catch (err) {
    return err;
  }
};

/**
 * Rota Management functions for Firebase
 */

const saveRotaData = async (uid, startAt, endAt, summary, color) => {
  try {
    await addDoc(collection(db, 'rota'), {
      uid,
      startAt,
      endAt,
      summary,
      color,
    });
    return true;
  } catch (err) {
    return err;
  }
};

const updateRotaData = async (uid, startAt, endAt, summary, color, docID) => {
  try {
    await updateDoc(doc(db, 'rota', docID), {
      startAt,
      endAt,
      summary,
      color,
    });
    return true;
  } catch (err) {
    return err;
  }
};

const deleteRotaData = async (docID) => {
  try {
    await deleteDoc(doc(db, 'rota', docID));
    return true;
  } catch (err) {
    return err;
  }
};

/**
 * Pay Management functions for Firebase
 */

const fetchDefaultPay = async (uid) => {
  try {
    const q = query(collection(db, 'wages'), where('uid', '==', uid));
    const doc = await getDocs(q);
    const data = doc.docs[0];
    return data;
  } catch (err) {
    return false;
  }
};

const saveWageData = async (uid, pay, payType, workingHours) => {
  try {
    await addDoc(collection(db, 'wages'), {
      uid,
      pay,
      payType,
      workingHours,
    });
    return true;
  } catch (err) {
    return err;
  }
};

const updateWageData = async (uid, pay, payType, workingHours, docID) => {
  try {
    await updateDoc(doc(db, 'wages', docID), {
      uid,
      pay,
      payType,
      workingHours,
    });
    return true;
  } catch (err) {
    return err;
  }
};

export {
  // Base Functions
  auth,
  db,
  // Account Functions
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  // Note Functions
  saveNoteData,
  updateNoteData,
  deleteNoteData,
  // Rota Functions
  saveRotaData,
  updateRotaData,
  deleteRotaData,
  // Pay Functions
  fetchDefaultPay,
  saveWageData,
  updateWageData,
};
