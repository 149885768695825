import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
  Card,
} from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoginIcon from '@mui/icons-material/Login';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, logInWithEmailAndPassword } from '../../firebase';
import './signin.styles.scss';
import MySnackbar from '../../Components/MySnackbar/mysnackbar.component';

function SignIn() {
  const theme = createTheme();
  const [user, loading] = useAuthState(auth);
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (loading) {
      return;
    }
    if (user) history.replace('/');
  }, [user, loading]);

  const handleChange = (e) => {
    const target = e.target.id;
    const { value } = e.target;
    setMessage('');
    setSeverity('');
    switch (target) {
      case 'email':
        setEmail(value);
        break;
      case 'password':
        setPassword(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // eslint-disable-next-line no-undef

    const result = await logInWithEmailAndPassword(email, password);
    if (result.message) {
      setMessage('Invalid Email or Password');
      setSeverity('error');
    } else {
      setMessage('Logged in');
      setSeverity('success');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container className='signinContainer'>
        <Container component='main' maxWidth='xs'>
          <Card
            sx={{
              marginTop: 8,
              padding: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: '#6f55b8' }}>
              <LoginIcon />
            </Avatar>
            <Typography component='h1' variant='p'>
              Sign in
            </Typography>
            <Box
              component='form'
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin='normal'
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                autoComplete='email'
                value={email}
                onChange={handleChange}
                autoFocus
              />
              <TextField
                margin='normal'
                required
                fullWidth
                name='password'
                label='Password'
                type='password'
                id='password'
                value={password}
                onChange={handleChange}
                autoComplete='current-password'
              />
              <Button
                onClick={handleSubmit}
                fullWidth
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
            </Box>
            <Typography>
              <Link to='/reset'>Forgotten Password?</Link>
            </Typography>
          </Card>
        </Container>
        <Container maxWidth='xs'>
          <Box component='div' className='register'>
            <Card
              sx={{
                marginTop: 8,
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: '#6f55b8' }}>
                <CheckCircleIcon />
              </Avatar>
              <Typography component='h1' variant='p'>
                Register
              </Typography>
              <Typography component='h2' variant='h5'>
                Sign up for a new account account today!
              </Typography>
              <Button fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
                <Link to='register'>Register Here!</Link>
              </Button>
            </Card>
          </Box>
        </Container>
      </Grid>
      <MySnackbar severity={severity} message={message} />
    </ThemeProvider>
  );
}

export default SignIn;
