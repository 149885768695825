/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  TextField,
  Typography,
  Container,
  Card,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  ListItemText,
} from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useAuthState } from 'react-firebase-hooks/auth';

import { collection, getDocs, query, where } from 'firebase/firestore';

import Kalend, { CalendarView } from 'kalend'; // import component
import 'kalend/dist/styles/index.css';
import './rota.styles.scss';
import AddIcon from '@mui/icons-material/Add';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  auth,
  db,
  deleteRotaData,
  saveRotaData,
  updateRotaData,
} from '../../firebase';
import MySnackbar from '../../Components/MySnackbar/mysnackbar.component';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const colours = [
  'indigo',
  'blue',
  'orange',
  'red',
  'pink',
  'crimson',
  'dodgerblue',
  'brown',
  'purple',
  'tomato',
  'MediumPurple',
  'salmon',
];

function Rota() {
  const theme = createTheme();
  const [user, loading, error] = useAuthState(auth);

  const [docID, setDocID] = useState('');
  const [eventTitle, setEventTitle] = useState('');
  const [startDateTime, setStartDateTime] = useState(null);
  const [endDateTime, setEndDateTime] = useState(null);
  const [eventColour, setEventColour] = useState('indigo');

  const [existing, setExisting] = useState(false);

  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState('');

  const setValueForDialog = (id, summary, startAt, endAt, color, update) => {
    setDocID(id);
    setEventTitle(summary);
    setStartDateTime(startAt);
    setEndDateTime(endAt);
    setEventColour(color);
    setExisting(update);
    return 1;
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setValueForDialog('', '', '', '', '', false);
    setOpen(false);
    setMessage('');
    setSeverity('');
  };

  const [events, setEvents] = useState([]);

  const fetchEvents = async () => {
    try {
      const q = query(collection(db, 'rota'), where('uid', '==', user.uid));
      const docs = await getDocs(q);
      const data = [];
      if (docs) {
        docs.forEach((doc) => {
          const docData = doc.data();
          data.push({
            id: doc.id,
            startAt: docData.startAt,
            endAt: docData.endAt,
            summary: docData.summary,
            color: docData.color,
          });
        });
      }
      setEvents(data);
    } catch (err) {
      setMessage('Error');
      setSeverity('error');
    }
  };

  const handleSaveEvent = async () => {
    const currentTime = new Date();
    if (user) {
      if (endDateTime && eventTitle && eventColour) {
        await saveRotaData(
          user.uid,
          moment(startDateTime || currentTime).format('YYYY-MM-DDTHH:mm:ssZ'),
          moment(endDateTime).format('YYYY-MM-DDTHH:mm:ssZ'),
          eventTitle,
          eventColour,
        );
        setMessage('Event Created');
        setSeverity('success');
        fetchEvents();
      } else {
        setMessage('Invalid values for event');
        setSeverity('error');
      }
    } else {
      setMessage('Please log in');
      setSeverity('error');
    }
    setOpen(false);
    setValueForDialog('', '', '', '', '', false);
  };

  const handleUpdateEvent = (
    docID,
    startDateTime,
    endDateTime,
    eventTitle,
    eventColour,
  ) => {
    if (user) {
      if (docID) {
        if (endDateTime && eventTitle && eventColour) {
          updateRotaData(
            user.uid,
            moment(startDateTime).format('YYYY-MM-DDTHH:mm:ssZ'),
            moment(endDateTime).format('YYYY-MM-DDTHH:mm:ssZ'),
            eventTitle,
            eventColour,
            docID,
          );
          setMessage('Event Updated');
          setSeverity('success');
        } else {
          setMessage('Invalid values for event');
          setSeverity('error');
        }
      } else {
        setMessage('Event no longer exists');
        setSeverity('error');
      }
      fetchEvents();
    } else {
      setMessage('Please log in');
      setSeverity('error');
    }
    setOpen(false);
    setValueForDialog('', '', '', '', '', false);
  };

  const handleDeleteEvent = () => {
    if (user && docID) {
      deleteRotaData(docID);
      setMessage('Event Deleted');
      setSeverity('success');
      fetchEvents();
    } else {
      setMessage('Please log in');
      setSeverity('error');
    }
    setOpen(false);
    setValueForDialog('', '', '', '', '', false);
  };

  const onNewEventClick = (data) => {
    setValueForDialog('', '', data.startAt, data.endAt, '', false);
    handleOpen();
  };

  // Callback for event click
  const onEventClick = (data) => {
    setValueForDialog(
      data.id,
      data.summary,
      data.startAt,
      data.endAt,
      data.color,
      true,
    );
    handleOpen();
  };

  // Callback after dragging is finished
  // eslint-disable-next-line no-unused-vars
  const onEventDragFinish = async (_prev, current, _data) => {
    await handleUpdateEvent(
      current.id,
      current.startAt,
      current.endAt,
      current.summary,
      current.color,
    );
  };

  const handleChange = (e) => {
    const target = e.target.id;
    const { value } = e.target;
    switch (target) {
      case 'title':
        setEventTitle(value);
        break;
      default:
        break;
    }
  };

  const handleSelectChange = (event) => {
    const { value } = event.target;
    setEventColour(value);
  };

  useEffect(() => {
    if (loading || error) return;
    if (user) {
      fetchEvents();
    } else {
      setMessage('Please log in');
      setSeverity('error');
    }
  }, [user]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>
        <Container>
          <Card
            sx={{
              marginTop: 2,
              padding: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
            }}
          >
            <Grid sx={{ marginTop: 2, padding: 2 }} container>
              <Avatar sx={{ m: 1, bgcolor: '#6f55b8' }}>
                <CalendarMonthIcon />
              </Avatar>
              <Typography sx={{ m: 0.5 }} component='h1' variant='p'>
                Rota
              </Typography>
            </Grid>
            <Button
              variant='contained'
              onClick={handleOpen}
              startIcon={<AddIcon />}
            >
              Add
            </Button>
            <div className='wrapper'>
              <div className='CalendarWrapper'>
                <Kalend
                  onEventClick={onEventClick}
                  onNewEventClick={onNewEventClick}
                  onEventDragFinish={onEventDragFinish}
                  events={events}
                  initialDate={new Date().toISOString()}
                  hourHeight={30}
                  initialView={CalendarView.WEEK}
                  timeFormat='24'
                  weekDayStart='Monday'
                  calendarIDsHidden={[]}
                  language='en'
                />
              </div>
            </div>
          </Card>
        </Container>
      </ThemeProvider>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create Event</DialogTitle>
        <DialogContent sx={{ m: 1, p: 1 }}>
          <DialogContentText>Enter event details</DialogContentText>
          <TextField
            autoFocus
            margin='normal'
            id='title'
            label='Title'
            fullWidth
            value={eventTitle}
            onChange={handleChange}
          />
          <DateTimePicker
            label='Start Date & Time'
            fullWidth
            minDate={moment()}
            value={startDateTime}
            onChange={(value) => setStartDateTime(value)}
            renderInput={(params) => <TextField {...params} />}
          />
          <DateTimePicker
            label='End Date & Time'
            fullWidth
            minDate={moment()}
            value={endDateTime}
            onChange={(value) => setEndDateTime(value)}
            renderInput={(params) => <TextField {...params} />}
          />
          <FormControl sx={{ m: 1, width: 300, mt: 3 }}>
            <Select
              defaultValue='indigo'
              value={eventColour}
              onChange={handleSelectChange}
              input={<OutlinedInput />}
              MenuProps={MenuProps}
            >
              {colours.map((colour) => (
                <MenuItem
                  key={colour}
                  value={colour}
                  sx={{ display: 'inline-flex', width: '100%' }}
                >
                  <ListItemText primary={colour} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          {existing ? (
            <>
              <Button
                onClick={() =>
                  handleUpdateEvent(
                    docID,
                    startDateTime,
                    endDateTime,
                    eventTitle,
                    eventColour,
                  )
                }
              >
                Update
              </Button>
              <Button onClick={handleDeleteEvent}>Delete</Button>
            </>
          ) : (
            <Button onClick={handleSaveEvent}>Save</Button>
          )}
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <MySnackbar severity={severity} message={message} />
    </LocalizationProvider>
  );
}

export default Rota;
