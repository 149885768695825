import { useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, logout } from '../../firebase';
import { useHistory } from 'react-router-dom';

const Logout = () => {
  const [user, loading] = useAuthState(auth);
  const history = useHistory();

  useEffect(() => {
    if (loading) return;
    if (user) logout();
    history.replace('/');
  }, [user, loading, history]);

  return (
    <Box component='div' className='navContainer'>
      <CircularProgress color='secondary' />
    </Box>
  );
};
export default Logout;
