import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  TextField,
  Box,
  Typography,
  Container,
  Card,
} from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useAuthState } from 'react-firebase-hooks/auth';

import { useHistory } from 'react-router-dom';
import { collection, where, query, getDocs } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import MySnackbar from '../../Components/MySnackbar/mysnackbar.component';

function Account() {
  const theme = createTheme();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState('');
  const [occupation, setOccupation] = useState('');
  const [user, loading] = useAuthState(auth);
  const history = useHistory();

  const fetchUser = async () => {
    try {
      const q = query(collection(db, 'users'), where('uid', '==', user.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.name);
      setEmail(data.email);
      setOccupation(data.occupation);
    } catch (err) {
      setMessage('Error fetching user informatin');
      setSeverity('error');
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) history.replace('/signin');
    fetchUser();
  }, [user, loading]);

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Card
          sx={{
            marginTop: 8,
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: '#6f55b8' }}>
            <AccountBoxIcon />
          </Avatar>
          <Typography component='h1' variant='p'>
            Account
          </Typography>
          <Box
            component='form'
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin='normal'
              required
              fullWidth
              id='fullname'
              label='Full Name'
              name='fullname'
              value={name}
              disabled
            />
            <TextField
              margin='normal'
              required
              fullWidth
              id='occupation'
              label='Occupation'
              name='occupation'
              value={occupation}
              disabled
            />
            <TextField
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              value={email}
              disabled
            />
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
            >
              Update
            </Button>
          </Box>
        </Card>
      </Container>
      <MySnackbar message={message} severity={severity} />
    </ThemeProvider>
  );
}

export default Account;
