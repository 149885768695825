import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import {
  Avatar,
  TextField,
  Box,
  Typography,
  Container,
  Card,
  Grid,
} from '@mui/material';
import TodayIcon from '@mui/icons-material/Today';

import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../../firebase';
import MySnackbar from '../../Components/MySnackbar/mysnackbar.component';

function Home() {
  const [user, loading] = useAuthState(auth);
  const [name, setName] = useState('');

  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, 'users'), where('uid', '==', user.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.name);
    } catch (err) {
      setMessage('An error occured while fetching user data');
      setSeverity('error');
    }
  };

  useEffect(() => {
    if (loading) return;
    if (user) {
      fetchUserName();
    } else {
      setName('');
    }
  }, [user, loading]);

  return (
    <Container>
      <Card
        sx={{
          marginTop: 2,
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
        }}
      >
        <Grid container>
          <Avatar sx={{ m: 1, bgcolor: '#6f55b8' }}>
            <TodayIcon />
          </Avatar>
          <Typography sx={{ m: 0.5 }} component='h1' variant='p'>
            Todays Agenda
          </Typography>
        </Grid>
        <Box component='form' noValidate sx={{ mt: 1 }}>
          <TextField
            margin='normal'
            required
            fullWidth
            id='fullname'
            label='Full Name'
            name='fullname'
            value={name}
            disabled
          />
        </Box>
      </Card>
      <MySnackbar message={message} severity={severity} />
    </Container>
  );
}

export default Home;
