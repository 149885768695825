/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  TextField,
  Typography,
  Container,
  Card,
  Grid,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableContainer,
  Paper,
  CardContent,
  CardActions,
  IconButton,
} from '@mui/material';
import NotesIcon from '@mui/icons-material/Notes';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewListIcon from '@mui/icons-material/ViewList';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

import { useAuthState } from 'react-firebase-hooks/auth';

import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { LocalizationProvider } from '@mui/x-date-pickers';

import {
  auth,
  db,
  deleteNoteData,
  saveNoteData,
  updateNoteData,
} from '../../firebase';
import MySnackbar from '../../Components/MySnackbar/mysnackbar.component';

function Notes() {
  const theme = createTheme();

  const [notes, setNotes] = useState([]);
  const [docID, setDocID] = useState(null);
  const [noteTitle, setNoteTitle] = useState('');
  const [noteText, setNoteText] = useState('');
  const [existing, setExisting] = useState(false);

  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState('');

  const [user, loading] = useAuthState(auth);

  const [dateTime, setDateTime] = useState(null);

  const [open, setOpen] = useState(false);

  const handleOpen = (update, note) => {
    setOpen(true);
    if (note) {
      setExisting(update);
      setDocID(note.id);
      setNoteTitle(note.title);
      setNoteText(note.note);
      setDateTime(note.reminder);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setExisting(false);
    setDocID(null);
    setNoteTitle('');
    setNoteText('');
    setDateTime(null);
  };

  const fetchNotes = async () => {
    try {
      const q = query(
        collection(db, 'notes'),
        where('uid', '==', user.uid),
        orderBy('created', 'desc'),
      );
      const docs = await getDocs(q);
      const data = [];
      if (docs) {
        docs.forEach((doc) => {
          const docData = doc.data();

          data.push({
            id: doc.id,
            created: docData.created,
            modified: docData.modified,
            note: docData.note,
            reminder: docData.reminder,
            title: docData.title,
            uid: docData.uid,
          });
        });
      }
      setNotes(data);
    } catch (err) {
      setMessage('Error fetching notes');
      setSeverity('error');
    }
  };

  const handleSave = () => {
    const currentTime = new Date();
    if (user) {
      if (noteText !== '' && noteTitle !== '') {
        let timestamp = moment(dateTime, 'M/D/YYYY hh:mm a').valueOf();
        const createdDate = moment(currentTime, 'M/D/YYYY hh:mm a').valueOf();
        const modifiedDate = moment(currentTime, 'M/D/YYYY hh:mm a').valueOf();

        if (timestamp <= createdDate) {
          timestamp = null;
        }

        saveNoteData(
          user.uid,
          noteTitle,
          noteText,
          timestamp,
          createdDate,
          modifiedDate,
        );

        setMessage('Note Created');
        setSeverity('success');
        fetchNotes();
      } else {
        setMessage('Invalid values for Note');
        setSeverity('error');
      }
    } else {
      setMessage('Please log in');
      setSeverity('error');
    }
    setOpen(false);
  };

  const handleDateTime = (value) => {
    setDateTime(value);
  };

  const handleUpdate = () => {
    const currentTime = new Date();
    if (user) {
      if (noteText !== '' && noteTitle !== '') {
        let timestamp = moment(dateTime, 'M/D/YYYY hh:mm a').valueOf();
        const modifiedDate = moment(currentTime, 'M/D/YYYY hh:mm a').valueOf();

        if (!timestamp) {
          timestamp = dateTime;
        }
        if (timestamp <= modifiedDate) {
          timestamp = null;
        }

        updateNoteData(docID, noteTitle, noteText, timestamp, modifiedDate);
        setMessage('Note Updated');
        setSeverity('success');
        fetchNotes();
      } else {
        setMessage('Invalid values for Note');
        setSeverity('error');
      }
    } else {
      setMessage('Please log in');
      setSeverity('error');
    }
    setOpen(false);
  };

  const handleDelete = (docID) => {
    if (user && docID) {
      deleteNoteData(docID);

      setMessage('Note Deleted');
      setSeverity('success');
      fetchNotes();
    } else {
      setMessage('Please log in');
      setSeverity('error');
    }
    setOpen(false);
  };

  const handleChange = (e) => {
    const target = e.target.id;
    const { value } = e.target;
    setMessage('');
    setSeverity('');
    switch (target) {
      case 'note':
        setNoteText(value);
        break;
      case 'title':
        setNoteTitle(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (loading) return;
    if (user) fetchNotes();
  }, [user, loading]);

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Card
          sx={{
            marginTop: 2,
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
          }}
        >
          <Grid container>
            <Avatar sx={{ m: 1, bgcolor: '#6f55b8' }}>
              <NotesIcon />
            </Avatar>
            <Typography sx={{ m: 0.5 }} component='h1' variant='p'>
              Notes
            </Typography>
          </Grid>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='flex-start'
            spacing={1}
          >
            <Grid item>
              <Button
                variant='contained'
                onClick={() => handleOpen(false, null)}
                startIcon={<AddIcon />}
              >
                Add
              </Button>
            </Grid>

            <Grid item>
              <ButtonGroup variant='contained'>
                <Button>
                  <ViewListIcon />
                </Button>
                <Button>
                  <GridViewIcon />
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            {notes
              ? notes.map((note) => (
                  <Table id={note.id} sx={{ minWidth: '650' }}>
                    <TableBody>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component='th' scope='row'>
                          <Card variant='outlined'>
                            <CardContent>
                              <Typography variant='h6' gutterBottom>
                                {note.title}
                              </Typography>
                              <TextField
                                label='Note Text'
                                multiline
                                rows={4}
                                value={note.note}
                                fullWidth
                                variant='outlined'
                                disabled
                              />
                            </CardContent>
                            <CardActions
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Typography
                                sx={{ marginRight: 'auto' }}
                                variant='body1'
                                gutterBottom
                              >
                                {note.reminder
                                  ? moment(note.reminder).format(
                                      'DD MMM YYYY hh:mm',
                                    )
                                  : null}
                              </Typography>
                              <IconButton
                                size='large'
                                aria-label='Update Note'
                                onClick={() => handleOpen(true, note)}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                size='large'
                                aria-label='Delete Note'
                                onClick={() => handleDelete(note.id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </CardActions>
                          </Card>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                ))
              : null}
          </TableContainer>
        </Card>
      </Container>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Create Note</DialogTitle>
          <DialogContent>
            <DialogContentText>Enter Note/Reminder details</DialogContentText>
            <TextField
              autoFocus
              margin='dense'
              id='title'
              label='Title'
              fullWidth
              variant='standard'
              value={noteTitle}
              onChange={handleChange}
            />
            <TextField
              autoFocus
              margin='dense'
              id='note'
              label='Note'
              multiline
              rows={4}
              fullWidth
              variant='standard'
              value={noteText}
              onChange={handleChange}
            />
            <DateTimePicker
              label='Reminder?'
              minDate={moment()}
              value={dateTime}
              onChange={handleDateTime}
              renderInput={(params) => <TextField {...params} />}
            />
          </DialogContent>
          <DialogActions>
            {existing ? (
              <Button onClick={handleUpdate}>Update</Button>
            ) : (
              <Button onClick={handleSave}>Save</Button>
            )}
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </LocalizationProvider>
      <MySnackbar message={message} severity={severity} />
    </ThemeProvider>
  );
}

export default Notes;
