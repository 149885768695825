/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  TextField,
  Typography,
  Container,
  Card,
  Grid,
  Paper,
  InputAdornment,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@mui/material';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TaxCalculator from 'tax-calculator-uk';

import { useAuthState } from 'react-firebase-hooks/auth';

import {
  auth,
  fetchDefaultPay,
  saveWageData,
  updateWageData,
} from '../../firebase';
import MySnackbar from '../../Components/MySnackbar/mysnackbar.component';

function Wages() {
  const theme = createTheme();

  const [docID, setDocID] = useState('');
  const [pay, setPay] = useState('');
  const [payType, setPayType] = useState(0);
  const [workingHours, setWorkingHours] = useState('');

  const [yearlyPay, setYearlyPay] = useState(0);
  const [homeMonthly, setHomeMonthly] = useState(0);
  const [taxPaid, setTaxPaid] = useState(0);
  const [NIPaid, setNIPaid] = useState(0);

  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState('');

  const [user, loading, error] = useAuthState(auth);

  const clear = () => {
    setYearlyPay(0);
    setHomeMonthly(0);
    setTaxPaid(0);
    setNIPaid(0);
  };

  const handleCalculation = (pay, payType, workingHours) => {
    let taxablePay = 0;
    const options = {
      age: 21,
      studentLoanPlan: 0,
      blind: false,
      pensionPercentage: 0,
    };

    switch (payType.toString()) {
      case '0':
        taxablePay = pay * workingHours * 52;
        break;
      case '1':
        taxablePay = pay * 52;
        break;
      case '2':
        taxablePay = pay * 4;
        break;
      case '3':
        taxablePay = pay;
        break;
      default:
        break;
    }

    if (pay) {
      if (payType.toString() === '0' && !(workingHours > 0)) {
        setMessage('Pay Tpye is set to hourly with no working hours');
        setSeverity('warning');
        clear();
      } else {
        const incomeTaxBreakdown = TaxCalculator(
          taxablePay,
          options,
        ).getTaxBreakdown();

        const tax = incomeTaxBreakdown.paye;
        let totalTax = 0;

        Object.entries(tax).forEach((item) => {
          const [key, value] = item;
          totalTax += value.tax;
        });

        setTaxPaid(Math.round(totalTax * 100) / 100);

        const ni = incomeTaxBreakdown.nationalInsurance;
        let totalNI = 0;

        Object.entries(ni).forEach((item) => {
          const [key, value] = item;
          totalNI += value.tax;
        });

        if (totalNI < 0) {
          setNIPaid(0);
          setYearlyPay(incomeTaxBreakdown.netIncome.yearly + totalNI);
          setHomeMonthly((incomeTaxBreakdown.netIncome.yearly + totalNI) / 12);
        } else {
          setNIPaid(Math.round(totalNI * 100) / 100);
          setYearlyPay(incomeTaxBreakdown.netIncome.yearly);
          setHomeMonthly(incomeTaxBreakdown.netIncome.monthly);
        }
        setMessage('Pay breakdown calculated');
        setSeverity('success');
      }
    } else {
      setMessage('Enter the amount you get paid');
      setSeverity('warning');
      clear();
    }
  };

  const fetchPay = async () => {
    try {
      const fetchedData = await fetchDefaultPay(user.uid);
      if (fetchedData) {
        const data = fetchedData.data();
        setDocID(fetchedData.id ? fetchedData.id : null);
        setPay(data.pay);
        setPayType(data.payType);
        setWorkingHours(data.workingHours);
        handleCalculation(data.pay, data.payType, data.workingHours);
      }
    } catch (err) {
      setMessage('Error fetching pay information');
      setSeverity('error');
    }
  };

  useEffect(() => {
    if (loading) return;
    if (user) {
      fetchPay();
    }
  }, [user, loading]);

  const handleChange = (e) => {
    const target = e.target.id;
    const { value } = e.target;
    setMessage('');
    setSeverity('');
    switch (target) {
      case 'pay':
        setPay(value);
        break;
      case 'payType':
        setPayType(value);
        break;
      case 'workingHours':
        setWorkingHours(value);
        break;
      default:
        break;
    }
  };

  const handleSetDefault = (e) => {
    if (user) {
      if (
        (pay && payType && workingHours) ||
        (pay !== '' && payType.toString() === '0' && workingHours !== '')
      ) {
        if (!docID) {
          saveWageData(user.uid, pay, payType, workingHours);
        } else {
          updateWageData(user.uid, pay, payType, workingHours, docID);
        }
        setMessage('Default pay set');
        setSeverity('success');
      } else {
        setMessage('Ensure all values are set');
        setSeverity('error');
      }
    } else {
      setMessage('Please Log In');
      setSeverity('error');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Card
          sx={{
            marginTop: 2,
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
          }}
        >
          <Grid container>
            <Avatar sx={{ m: 1, bgcolor: '#6f55b8' }}>
              <CurrencyPoundIcon />
            </Avatar>
            <Typography sx={{ m: 0.5 }} component='h1' variant='p'>
              Wages
            </Typography>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs md={7}>
              <Paper sx={{ mt: 1, p: 1 }}>
                <TextField
                  id='pay'
                  label='Pay'
                  sx={{ minWidth: '180px' }}
                  fullWidth
                  value={pay}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>£</InputAdornment>
                    ),
                  }}
                />
                <FormControl>
                  <FormLabel id='payTypeLabel'>Pay Type</FormLabel>
                  <RadioGroup
                    aria-labelledby='payTypeLabel'
                    id='payType'
                    value={payType}
                    onChange={(e) => setPayType(e.target.value)}
                    sx={{ ml: 2 }}
                    row
                  >
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label='Hourly'
                    />
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label='Weekly'
                    />
                    <FormControlLabel
                      value={2}
                      control={<Radio />}
                      label='Quarterly'
                    />
                    <FormControlLabel
                      value={3}
                      control={<Radio />}
                      label='Annually'
                    />
                  </RadioGroup>
                </FormControl>

                <TextField
                  id='workingHours'
                  label='Working Hours'
                  margin='dense'
                  fullWidth
                  value={workingHours}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='start'>Hrs</InputAdornment>
                    ),
                  }}
                />
                <Button
                  fullWidth
                  onClick={() => handleCalculation(pay, payType, workingHours)}
                  variant='contained'
                  sx={{ m: 2, p: 2 }}
                >
                  Calculate!
                </Button>
                <Button
                  fullWidth
                  onClick={handleSetDefault}
                  variant='contained'
                  sx={{ m: 2, p: 2 }}
                >
                  Set As Default!
                </Button>
              </Paper>
            </Grid>
            <Grid item xs md={5}>
              <Paper sx={{ mt: 1, p: 1 }}>
                <TextField
                  fullWidth
                  sx={{ minWidth: '180px' }}
                  value={yearlyPay}
                  onChange={handleChange}
                  size='small'
                  margin='dense'
                  id='yearly'
                  label='Yearly Wage'
                  name='yearly'
                  disabled
                />
                <TextField
                  fullWidth
                  value={homeMonthly}
                  onChange={handleChange}
                  size='small'
                  margin='dense'
                  id='monthly'
                  label='Take Home Pay Per Month'
                  name='monthly'
                  disabled
                />
                <TextField
                  fullWidth
                  value={taxPaid}
                  onChange={handleChange}
                  size='small'
                  margin='dense'
                  id='taxPaid'
                  label='Tax Paid'
                  name='taxPaid'
                  disabled
                />
                <TextField
                  fullWidth
                  value={NIPaid}
                  onChange={handleChange}
                  size='small'
                  margin='dense'
                  id='niPaid'
                  label='NI Paid'
                  name='niPaid'
                  disabled
                />
              </Paper>
            </Grid>
          </Grid>
        </Card>
      </Container>
      <MySnackbar message={message} severity={severity} />
    </ThemeProvider>
  );
}

export default Wages;
