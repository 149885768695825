import { Switch, Route } from 'react-router-dom';
import Header from '../header/header.component';
import Home from '../../Pages/home/home.page';
import Wages from '../../Pages/wages/wages.page';
import Rota from '../../Pages/rota/rota.page';
import Notes from '../../Pages/notes/notes.page';
import SignIn from '../../Pages/signin/signin.page';
import Register from '../../Pages/register/register.page';
import Reset from '../../Pages/reset/reset.page';
import Account from '../../Pages/account/account.page';
import Logout from '../logout/logout.component';
import './App.scss';
import { ToastContainer } from 'react-toastify';
import { useLocation, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import useWindowDimensions from '../../Util/dynamicWindowDimensions';
import logo from '../../Assets/Logo.png';

const App = () => {
  const location = useLocation();
  const history = useHistory();
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (location.pathname !== '/') {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='App'>
      <div className='routes'>
        <div className='container'>
          {width < 768 ? (
            <img
              className='logo'
              src={logo}
              alt='Roge Logo'
              width={50}
              height={50}
            />
          ) : null}
          <div className='header'>
            <Header />
          </div>
        </div>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/wages' component={Wages} />
          <Route exact path='/rota' component={Rota} />
          <Route exact path='/notes' component={Notes} />
          <Route exact path='/signin' component={SignIn} />
          <Route exact path='/register' component={Register} />
          <Route exact path='/reset' component={Reset} />
          <Route exact path='/account' component={Account} />
          <Route exact path='/logout' component={Logout} />
        </Switch>
      </div>
    </div>
  );
};

export default App;
