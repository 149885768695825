import { useEffect, useState } from 'react';
import {
  Box,
  List,
  ListItemIcon,
  ListItemText,
  Divider,
  SwipeableDrawer,
} from '@mui/material';
import MuiListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';
import HomeIcon from '@mui/icons-material/Home';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuthState } from 'react-firebase-hooks/auth';
import { toast } from 'react-toastify';

import logo from '../../Assets/Logo.png';
import './header.styles.scss';
import { auth } from '../../firebase';
import useWindowDimensions from '../../Util/dynamicWindowDimensions';

const ListItem = withStyles({
  root: {
    '& .MuiListItemIcon-root': {
      color: 'white',
    },
    '&$selected': {
      color: '#6f55b8',
      '& .MuiListItemIcon-root': {
        color: '#6f55b8',
      },
    },
    '&$selected:hover': {
      color: '#6f55b8',
      '& .MuiListItemIcon-root': {
        color: '#6f55b8',
      },
    },
    '&:hover': {
      color: '#6f55b8',
      '& .MuiListItemIcon-root': {
        color: '#6f55b8',
      },
    },
  },
  selected: {},
})(MuiListItem);

const NewSwipeableDrawer = withStyles({
  root: {
    '& .MuiDrawer-paper': {
      background: '#252525',
    },
  },
  selected: {},
})(SwipeableDrawer);

function Header() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [user, loading, error] = useAuthState(auth);
  const [open, setOpen] = useState(false);
  const { width } = useWindowDimensions();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (loading) return;
    if (error) {
      toast(error, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [user, loading, error, width]);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  function TopLinks() {
    return (
      <div>
        <Link className='link' to='/'>
          <ListItem
            button
            selected={selectedIndex === 0}
            onClick={(event) => handleListItemClick(event, 0)}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary='Home' />
          </ListItem>
        </Link>
        <Link className='link' to='wages'>
          <ListItem
            button
            selected={selectedIndex === 1}
            onClick={(event) => handleListItemClick(event, 1)}
          >
            <ListItemIcon>
              <AttachMoneyIcon />
            </ListItemIcon>
            <ListItemText primary='Wages' />
          </ListItem>
        </Link>
        <Link className='link' to='rota'>
          <ListItem
            button
            selected={selectedIndex === 2}
            onClick={(event) => handleListItemClick(event, 2)}
          >
            <ListItemIcon>
              <DateRangeIcon />
            </ListItemIcon>
            <ListItemText primary='Rota' />
          </ListItem>
        </Link>
        <Link className='link' to='notes'>
          <ListItem
            button
            selected={selectedIndex === 3}
            onClick={(event) => handleListItemClick(event, 3)}
          >
            <ListItemIcon>
              <NoteAltIcon />
            </ListItemIcon>
            <ListItemText primary='Notes' />
          </ListItem>
        </Link>
      </div>
    );
  }

  function BottomLinks() {
    return (
      <List component='nav' className='bottom' aria-label='Account Navigation'>
        {user ? (
          <div>
            <Divider />
            <Link className='link' to='account'>
              <ListItem
                button
                selected={selectedIndex === 4}
                onClick={(event) => handleListItemClick(event, 4)}
              >
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primary='Account' />
              </ListItem>
            </Link>
            <Link className='link' to='logout'>
              <ListItem
                button
                selected={selectedIndex === 5}
                onClick={(event) => handleListItemClick(event, 5)}
              >
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary='Log Out' />
              </ListItem>
            </Link>
          </div>
        ) : (
          <div>
            <Divider />
            <Link className='link' to='signin'>
              <ListItem
                button
                selected={selectedIndex === 4}
                onClick={(event) => handleListItemClick(event, 4)}
              >
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primary='Sign In' />
              </ListItem>
            </Link>
          </div>
        )}
      </List>
    );
  }

  return (
    <Box component='div' className='navContainer'>
      {width >= 768 ? (
        <>
          <List component='nav' className='top' aria-label='Main Navigation'>
            <Link
              className='logo-container'
              to='/'
              onClick={(event) => handleListItemClick(event, 0)}
            >
              <img
                className='logo'
                src={logo}
                alt='Roge Logo'
                width={100}
                height={100}
              />
            </Link>
            <Divider />
            <TopLinks />
          </List>
          <BottomLinks />
        </>
      ) : (
        <>
          <Box
            className='drawer-container'
            onClick={(event) => toggleDrawer(event)}
            sx={{ color: '#6f55b8', alignSelf: 'center' }}
          >
            <MenuIcon />
          </Box>
          <NewSwipeableDrawer
            anchor='right'
            open={open}
            onClose={toggleDrawer}
            onOpen={toggleDrawer}
            className='drawer'
          >
            <TopLinks />
            <BottomLinks />
          </NewSwipeableDrawer>
        </>
      )}
    </Box>
  );
}
export default Header;
