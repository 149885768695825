import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Button,
  TextField,
  Box,
  Typography,
  Container,
  Card,
} from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useAuthState } from 'react-firebase-hooks/auth';
import './reset.styles.scss';
import { auth, sendPasswordReset } from '../../firebase';

const Reset = () => {
  const theme = createTheme();
  const [user, loading] = useAuthState(auth);
  const history = useHistory();

  useEffect(() => {
    if (loading) return;
    if (user) history('/dashboard');
  }, [user, loading, history]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    sendPasswordReset(data.get('email'));
  };

  return (
    <ThemeProvider theme={theme}>
      <Container className='resetContainer'>
        <Card
          sx={{
            marginTop: 8,
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: '#6f55b8' }}>
            <LoginIcon />
          </Avatar>
          <Typography component='h1' variant='p'>
            Forgot Password
          </Typography>
          <Box
            component='form'
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              autoComplete='email'
            />
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
            >
              Send Reset Email If Account Exists!
            </Button>
          </Box>
          <Typography>
            Don't have an account? <Link to='/register'>Register</Link> now.
          </Typography>
        </Card>
      </Container>
    </ThemeProvider>
  );
};

export default Reset;
