import React, { useEffect, useState } from 'react';
import { Snackbar, Alert } from '@mui/material';

function MySnackbar(props) {
  const [severity, setSeverity] = useState('success');
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setSeverity('');
    setMessage('');
  };

  useEffect(() => {
    setSeverity(props.severity);
    setMessage(props.message);
    if (props.severity !== '' && props.message !== '') {
      setOpen(true);
    }
  }, [props]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      {severity ? (
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      ) : null}
    </Snackbar>
  );
}

export default MySnackbar;
