import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  TextField,
  Box,
  Typography,
  Container,
  Card,
} from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useAuthState } from 'react-firebase-hooks/auth';

import { useHistory, Link } from 'react-router-dom';
import { auth, registerWithEmailAndPassword } from '../../firebase';

import './register.styles.scss';
import MySnackbar from '../../Components/MySnackbar/mysnackbar.component';

function Register() {
  const theme = createTheme();
  const [user, loading] = useAuthState(auth);
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstname, setFirstname] = useState('');
  const [surname, setSurname] = useState('');
  const [occupation, setOccupation] = useState('');

  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (loading) return;
    if (user) history.replace('/account');
  }, [user, loading]);

  const handleChange = (e) => {
    const target = e.target.id;
    const { value } = e.target;
    setMessage('');
    setSeverity('');
    switch (target) {
      case 'email':
        setEmail(value);
        break;
      case 'password':
        setPassword(value);
        break;
      case 'first-name':
        setFirstname(value);
        break;
      case 'surname':
        setSurname(value);
        break;
      case 'occupation':
        setOccupation(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // eslint-disable-next-line no-undef
    const fullName = `${firstname} ${surname}`;

    if (
      email !== '' &&
      firstname !== '' &&
      surname !== '' &&
      password !== '' &&
      occupation !== ''
    ) {
      registerWithEmailAndPassword(fullName, email, password, occupation);
      setSeverity('success');
      setMessage('Account Created');
    } else {
      setSeverity('error');
      setMessage('Enter all the required fields');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container className='registerContainer'>
        <Card
          sx={{
            marginTop: 8,
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: '#6f55b8' }}>
            <LoginIcon />
          </Avatar>
          <Typography component='h1' variant='p'>
            Register
          </Typography>
          <Box
            component='form'
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin='normal'
              required
              fullWidth
              id='first-name'
              label='First Name'
              name='first-name'
              value={firstname}
              onChange={handleChange}
              autoComplete='first-name'
              autoFocus
            />
            <TextField
              margin='normal'
              required
              fullWidth
              id='surname'
              label='Last Name'
              name='surname'
              value={surname}
              onChange={handleChange}
              autoComplete='surname'
            />
            <TextField
              margin='normal'
              required
              fullWidth
              id='occupation'
              label='Occupation'
              name='occupation'
              value={occupation}
              onChange={handleChange}
              autoComplete='occupation'
            />
            <TextField
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              value={email}
              onChange={handleChange}
              autoComplete='email'
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              type='password'
              id='password'
              value={password}
              onChange={handleChange}
              autoComplete='current-password'
            />
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
            >
              Register!
            </Button>
          </Box>
          <Typography>
            Already have an account? <Link to='/signin'>Sign In</Link> now.
          </Typography>
        </Card>
      </Container>
      <MySnackbar severity={severity} message={message} />
    </ThemeProvider>
  );
}

export default Register;
